<template>
	<div class="topbar-item">
		<!-- <div class="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary" id="kt_quick_panel_toggle">
			<span class="svg-icon svg-icon-xl svg-icon-primary">
				<v-icon color="blue">mdi-alarm</v-icon>
			</span>
			<span class="pulse-ring"></span>
		</div> -->
		<div
			class="notification-template d-flex align-items-center ms-1 ms-lg-3 mr-4"
			style="position: relative"
		>
			<v-menu offset-y left :close-on-content-click="false">
				<template v-slot:activator="{ on, attrs }">
					<div
						v-bind="attrs"
						v-on="on"
						class="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
					>
						<span class="symbol symbol-30 symbol-light-success" @click="goToNotify()">
							<span class="symbol-label font-size-h5 font-weight-bold" style="position: relative">
								<div v-if="notificationLoading">
									<div
										role="progressbar"
										aria-valuemin="0"
										aria-valuemax="100"
										class="v-progress-circular v-progress-circular--visible v-progress-circular--indeterminate primary--text"
										style="height: 18px; width: 18px"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715"
											style="transform: rotate(0deg)"
										>
											<circle
												fill="transparent"
												cx="45.714285714285715"
												cy="45.714285714285715"
												r="20"
												stroke-width="5.714285714285714"
												stroke-dasharray="125.664"
												stroke-dashoffset="125.66370614359172px"
												class="v-progress-circular__overlay"
											></circle>
										</svg>
										<div class="v-progress-circular__info"></div>
									</div>
								</div>
								<template v-else>
									<i class="mdi mdi-bell-outline" style="font-size: 22px; color: rgb(126 130 179)"></i>
									<span class="count-badge">{{ notificationCount }}</span>
								</template>
							</span>
						</span>
					</div>
				</template>

				<div class="notification-list" v-if="false">
					<div
						class="menu menu-sub menu-sub-dropdown menu-column"
						style="
							width: 480px;
							box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
								0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
						"
					>
						<!--begin::Heading-->
						<div class="d-flex flex-column rounded-top" style="background-color: #f6f9fc">
							<!--begin::Title-->
							<h3 class="fw-semobold px-4 mt-3 mb-3 font-level-5 w-100">
								Notifications
								<span class="fs-8 opacity-75 ps-3 font-level-3">({{ notifications.length }})</span>
								<div class="mt-2">
									<v-chip
										:color="all_notification ? 'blue darken-4 white--text' : 'grey lighten-2'"
										v-on:click="
											all_notification = true;
											init_notification();
										"
										small
										>All</v-chip
									>
									<v-chip
										:color="all_notification ? 'grey lighten-2' : 'blue darken-4 white--text'"
										v-on:click="
											all_notification = false;
											init_notification('unread');
										"
										class="ml-2"
										small
										>Unread</v-chip
									>
								</div>
							</h3>
							<!--end::Title-->

							<!--begin::Items-->
							<div class="bg-white" style="max-height: 325px; overflow-y: scroll">
								<div v-if="notificationLoading" class="px-10 py-10">
									<v-progress-linear
										color="blue darken-4"
										indeterminate
										rounded
										height="6"
									></v-progress-linear>
								</div>
								<table v-else-if="notifications.length" width="100%" style="table-layout: fixed">
									<tr v-for="(item, index) in notifications" :key="index">
										<td width="80%" class="py-2 px-4">
											<div class="mb-0 me-2">
												<span
													class="blue--text text--darken-4 fs-7 text-gray-800 text-hover-primary fw-bold cursor-pointer"
													v-on:click="route_to_detail(item)"
													>{{ item.message }}
												</span>
												<div class="text-gray-400 fs-7">
													{{ item.description }}
												</div>
											</div>
										</td>
										<td width="20%" align="center" valign="middle" class="px-4 py-2">
											<v-chip
												class="mb-2"
												v-if="item.is_marked == 0"
												x-small
												v-on:click="mark_as_read(item)"
												color="blue darken-4 white--text"
											>
												Read
											</v-chip>
											<div style="font-size: 11px">{{ formatDateTime(item.datetime) }}</div>
										</td>
									</tr>
								</table>
								<div v-else class="d-flex align-center justify-center flex-column">
									<v-img max-width="200px" :src="$assetURL('media/notification-bell.gif')"></v-img>
									<p class="h1-label py-4" style="text-transform: unset !important">No notifications yet!</p>
								</div>
							</div>
							<!--end::Items-->
							<!--end::Tabs-->
						</div>
					</div>
				</div>
			</v-menu>
		</div>

		<div
			class="btn btn-icon btn-active-light-success position-relative w-30px h-30px w-md-40px h-md-40px mr-5"
			v-if="getPermission('reminder:view')"
		>
			<span class="symbol symbol-30 symbol-light-success" @click="goToReminder()">
				<span class="symbol-label font-size-h5 font-weight-bold" style="position: relative">
					<div v-if="notificationLoading">
						<div
							role="progressbar"
							aria-valuemin="0"
							aria-valuemax="100"
							class="v-progress-circular v-progress-circular--visible v-progress-circular--indeterminate primary--text"
							style="height: 18px; width: 18px"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715"
								style="transform: rotate(0deg)"
							>
								<circle
									fill="transparent"
									cx="45.714285714285715"
									cy="45.714285714285715"
									r="20"
									stroke-width="5.714285714285714"
									stroke-dasharray="125.664"
									stroke-dashoffset="125.66370614359172px"
									class="v-progress-circular__overlay"
								></circle>
							</svg>
							<div class="v-progress-circular__info"></div>
						</div>
					</div>
					<template v-else>
						<div>
							<v-icon color="blue"> mdi-alarm</v-icon>
						</div>
					</template>
				</span>
			</span>
		</div>
		<!-- <router-link to="/reminder-listing">
			<v-icon color="blue"> mdi-alarm</v-icon>
		</router-link> -->

		<!-- begin::Quick Panel -->
		<div
			id="kt_quick_panel"
			ref="kt_quick_panel"
			class="offcanvas offcanvas-right pt-5 pb-10"
			style="overflow: hidden"
		>
			<!--begin::Header-->
			<div
				class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
			>
				<h5 class="font-weight-bold pl-5">Notifications</h5>
				<div class="offcanvas-close mt-n1 pr-5">
					<a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close">
						<i class="ki ki-close icon-xs text-muted"></i>
					</a>
				</div>
			</div>
			<!--end::Header-->

			<div class="offcanvas-content px-10">
				<div class="tab-content">
					<div class="scroll pr-7 mr-n7" id="kt_quick_panel_logs">
						<div class="mb-15">
							<!-- <div v-for="(reminder, index) in reminderData" :key="index">
								<ReminderTab
									:open_details="!index ? true : false"
									:remind="reminder"
									:index-reminder="index"
								></ReminderTab>
							</div> -->
							<!-- <template v-for="(item, i) in list2">
								
								<div
									class="d-flex align-items-center rounded p-5 mb-5"
									v-bind:key="i"
									v-bind:class="`bg-light-${item.type}`"
								>
									<span class="svg-icon mr-5" v-bind:class="`svg-icon-${item.type}`">
										<inline-svg :src="item.svg" class="h-50 align-self-center" />
									</span>

									<div class="d-flex flex-column flex-grow-1 mr-2">
										<a href="#" class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">
											{{ item.title }}
										</a>
										<span class="text-muted font-size-sm">
											{{ item.desc }}
										</span>
									</div>

									<span class="font-weight-bolder py-1 font-size-lg" v-bind:class="`text-${item.type}`">
										{{ item.alt }}
									</span>
								</div>
							
							</template> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end::Quick Panel -->
		<ReminderListing
			v-if="create_task"
			:drawer="create_task"
			title-status="Create"
			v-on:close="create_task = false"
		></ReminderListing>
	</div>
</template>
<style>
/* .v-overlay.v-overlay--active.theme--dark {
    z-index: -1 !important;
} */
</style>
<script>
import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
import { QUERY, /* GET, */ PATCH } from "@/core/services/store/request.module";
/* import ReminderTab from "@/view/pages/leads/components/ReminderTab"; */
import { mapGetters } from "vuex";
import ReminderListing from "@/view/pages/leads/create/ReminderListing";
import {
	SET_NOTIFICATION_DRAWER,
	SET_NOTIFICATION_COUNT,
} from "@/core/services/store/common.module";

export default {
	name: "KTQuickPanel",
	data() {
		return {
			notifyCount: 0,
			notifications: [],
			notificationLoading: false,
			all_notification: false,
			tabIndex: 0,
			create_task: false,
			list1: [
				{
					title: "Top Authors",
					desc: "Most Successful Fellas",
					alt: "+82$",
					svg: "media/svg/misc/006-plurk.svg",
				},
				{
					title: "Popular Authors",
					desc: "Most Successful Fellas",
					alt: "+280$",
					svg: "media/svg/misc/006-plurk.svg",
				},
				{
					title: "New Users",
					desc: "Most Successful Fellas",
					alt: "+4500$",
					svg: "media/svg/misc/015-telegram.svg",
				},
				{
					title: "Active Customers",
					desc: "Most Successful Fellas",
					alt: "+4500$",
					svg: "media/svg/misc/003-puzzle.svg",
				},
			],
			list2: [
				{
					title: "Another purpose persuade",
					desc: "Due in 2 Days",
					alt: "+28%",
					svg: "media/svg/icons/Home/Library.svg",
					type: "warning",
				},
				{
					title: "Would be to people",
					desc: "Due in 2 Days",
					alt: "+50%",
					svg: "media/svg/icons/Communication/Write.svg",
					type: "success",
				},
				{
					title: "Purpose would be to persuade",
					desc: "Due in 2 Days",
					alt: "-27%",
					svg: "media/svg/icons/Communication/Group-chat.svg",
					type: "danger",
				},
				{
					title: "The best product",
					desc: "Due in 2 Days",
					alt: "+8%",
					svg: "media/svg/icons/General/Attachment2.svg",
					type: "info",
				},
			],
			reminderData: [],
			list3: [
				{
					title: "5 new user generated report",
					desc: "Reports based on sales",
					icon: "flaticon-bell text-success",
				},
				{
					title: "2 new items submited",
					desc: "by Grog John",
					icon: "flaticon2-box text-danger",
				},
				{
					title: "79 PSD files generated",
					desc: "Reports based on sales",
					icon: "flaticon-psd text-primary",
				},
				{
					title: "$2900 worth producucts sold",
					desc: "Total 234 items",
					icon: "flaticon2-supermarket text-warning",
				},
				{
					title: "4.5h-avarage response time",
					desc: "Fostest is Barry",
					icon: "flaticon-paper-plane-1 text-success",
				},
				{
					title: "3 Defence alerts",
					desc: "40% less alerts thar last week",
					icon: "flaticon-safe-shield-protection text-danger",
				},
				{
					title: "Avarage 4 blog posts per author",
					desc: "Most posted 12 time",
					icon: "flaticon-notepad text-primary",
				},
				{
					title: "16 authors joined last week",
					desc: "9 photodrapehrs, 7 designer",
					icon: "flaticon-users-1 text-warning",
				},
				{
					title: "2 new items have been submited",
					desc: "by Grog John",
					icon: "flaticon2-box text-info",
				},
				{
					title: "2.8 GB-total downloads size",
					desc: "Mostly PSD end  AL concepts",
					icon: "flaticon2-download text-success",
				},
				{
					title: "$2900 worth producucts sold",
					desc: "Total 234 items",
					icon: "flaticon2-supermarket text-danger",
				},
				{
					title: "7 new user generated report",
					desc: "Reports based on sales",
					icon: "flaticon-bell text-primary",
				},
				{
					title: "4.5h-avarage response time",
					desc: "Fostest is Barry",
					icon: "flaticon-paper-plane-1 text-success",
				},
			],
		};
	},
	components: {
		ReminderListing,
	},
	mounted() {
		// Init Quick Offcanvas Panel
		this.getReminder();
		KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
		this.init_notification();
	},
	methods: {
		/**
		 * Set current active on click
		 * @param event
		 */
		/*  reminderData(){
        
		 }, */
		goToNotify() {
			this.$store.commit(SET_NOTIFICATION_DRAWER, true);
		},
		goToReminder() {
			this.$router.push({
				name: `all-reminder`,
			});
		},
		mark_as_read(item) {
			if (item.id) {
				let unread = this.all_notification == false ? 1 : 0;
				this.$store
					.dispatch(PATCH, {
						url: `notifications/${item.id}/?unread=` + unread,
					})
					.then((data) => {
						//console.log(data);
						this.notifications = data;
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			}
		},
		init_notification(value = "all") {
			console.log(value);
			this.$store.dispatch(SET_NOTIFICATION_COUNT);
			// this.$store.dispatch(GET,{
			// 	url: "all-notifications/?type="+value,
			// }).then((data) => {
			// 		this.notifications = data.tbody;
			// }).catch((error) => {
			// 	console.log({
			// 		error,
			// 	});
			// });
			// this.$store
			// 	.dispatch(GET, {
			// 		url: "notification-count",
			// 	})
			// 	.then((data) => {
			// 		//this.notifications = data.tbody;
			// 		this.notifyCount = data.unread;
			// 	})
			// 	.catch((error) => {
			// 		console.log({
			// 			error,
			// 		});
			// 	});
		},
		getReminder() {
			this.$store
				.dispatch(QUERY, { url: `reminder`, data: { all: 1 } })
				.then((data) => {
					this.reminderData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		setActiveTab(event) {
			const tab = event.target.closest('[role="tablist"]');
			const links = tab.querySelectorAll(".nav-link");
			// remove active tab links
			for (let i = 0; i < links.length; i++) {
				links[i].classList.remove("active");
			}

			// set clicked tab index to bootstrap tab
			this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

			// set current active tab
			event.target.classList.add("active");
		},
	},
	computed: {
		...mapGetters(["notificationCount"]),
	},
};
</script>
<style scoped>
.pulse .pulse-ring {
	animation: animation-pulse 2s ease-out;
}
.count-badge {
	position: absolute;
	top: -10px;
	right: -8px;
	background: #0093ff;
	height: 20px;
	min-width: 20px;
	color: white;
	border-radius: 8px;
	padding: 2px;
	font-size: 12px;
}
</style>
